<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Dashboard"></page-header>
    <main class="flex-1 p-6 flex space-x-6 overflow-y-scroll">
      <!-- <div class="w-full bg-white p-5 rounded shadow-md"> -->
      <div class="w-full md:w-1/3">
        <EnquiriesWidget />
      </div>
      <div class="w-full md:w-1/3">
        <ServiceTicketWidget />
      </div>
      <div class="w-full md:w-1/3">
        <ReturnCodeWidget />
      </div>
    </main>
  </div>
</template>

<script>
const EnquiriesWidget = () => import("@/components/Dashboard/Enquiries/EnquiriesWidget.vue");
const ReturnCodeWidget = () => import("@/components/Dashboard/ReturnCodes/ReturnCodeWidget.vue");
const ServiceTicketWidget = () => import("@/components/Dashboard/ServiceTickets/ServiceTicketWidget.vue");
const PageHeader = () => import("@/components/PageHeader.vue");

export default {
  name: "dashboard",
  components: {
    PageHeader,
    EnquiriesWidget,
    ReturnCodeWidget,
    ServiceTicketWidget,
  },
  data() {
    return {
      prospects: [],
    };
  },
};
</script>
